import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AddEMS from "./AddEMS";
import AddSLC from "./AddSLC";
import ErrorToast from "../Toasts/ErrorToast";

const api_url = process.env.REACT_APP_API_URL;

const AddDevice = () => {
  const navigate = useNavigate();
  const devices = ["EMS", "SLC"];
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [device, setDevice] = useState(devices[0]);

  const [name, setName] = useState("");
  const [id, setId] = useState("");

  const addDevice = () => {
    console.log("addDevice");

    if (name === "") {
      setError(true);
      setErrorMsg("Name is required");
      return;
    }

    fetch(api_url + "/device/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        name: name,
        [device === "EMS" ? "ems" : "slc"]: id,
        type: device,
      }),
    })
      .then((res) => {
        if (res.status === 201) {
          return res.json();
        } else {
          console.log(res);
          setError(true);
          setErrorMsg("Error adding device");
        }
      })
      .then((data) => {
        //console.log(data);
        setError(false);
        setName("");
        setId("");

        //reload page
        //window.location.reload();
        document.getElementById("dismissButton").click();
        navigate("/device/" + data._id);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    //dismis modal after 1s
    //setTimeout(() => {
    //  document.getElementById("dismissButton").click();
    //}, 1000);
  };
  return (
    <>
      {error && <ErrorToast text={errorMsg} handler={setError} />}
      <button
        type="button"
        class="btn btn-success mt-3 mb-3"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal">
        Add Device
      </button>
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Add Device
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <select
                class="form-select"
                onChange={(e) => setDevice(e.target.value)}>
                {devices.map((device) => (
                  <option value={device}>{device}</option>
                ))}
              </select>
              <div class="mt-3">
                {device === "EMS" ? (
                  <AddEMS name={name} setName={setName} id={id} setId={setId} />
                ) : (
                  <AddSLC name={name} setName={setName} id={id} setId={setId} />
                )}
              </div>
            </div>
            <div class="modal-footer">
              <button
                id="dismissButton"
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal">
                Close
              </button>
              <button type="button" class="btn btn-primary" onClick={addDevice}>
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDevice;
