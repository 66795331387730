import React, { useState } from "react";
import Configurator from "../components/Configurator/Configurator";
import Debug from "../components/Configurator/Debug";

//import { AuthContext } from "../context/AuthProvider";

const DeviceConfig = () => {
  //const context = useContext(AuthContext);
  const [serialData, setSerialData] = useState([]);
  const [serialPort, setSerialPort] = useState(null);
  const [reader, setReader] = useState(null);
  const [config, setConfig] = useState({}); // {wifi_setup: {ssid: "xxx", password: "xxx"}

  const connect = async () => {
    const vendorId = 0x0403;
    const productId = 0x6015;

    try {
      const port = await navigator.serial.requestPort({
        filters: [{ usbVendorId: vendorId, usbProductId: productId }],
      });
      console.log("Connected port", port);

      await port.open({ baudRate: 115200 });
      await port.setSignals({ requestToSend: true });
      //await port.setSignals({ dataTerminalReady: true });

      // Get a reader from the readable stream
      const reader = port.readable.getReader();
      let buffer = "";

      // Define the event handler function
      const handleEvent = async () => {
        try {
          // Read data from the stream
          const { value, done } = await reader.read();

          if (done) {
            console.log("Read operation completed.");
            return;
          }

          let data = new TextDecoder().decode(value);
          console.log("data", data);

          buffer += data;

          // Process the received data
          console.log("Received data:", data);
          if (data.includes("\n")) {
            try {
              const json = JSON.parse(buffer);
              console.log("json", json.read_config);

              let config = {};
              if (json.read_config) {
                config = json.read_config;
                setConfig(config);
              }
              console.log("config", config);
            } catch (e) {
              console.log("error", e);
            }
            const timestamp = new Date().toLocaleTimeString();
            buffer = timestamp + " " + buffer;
            console.log("buffer", buffer);
            const out = buffer;
            setSerialData((prevState) => [...prevState, out]);
            buffer = "";
          }
          //const timestamp = new Date().toLocaleTimeString();
          //data = timestamp + " " + data;
          //
          //setSerialData((prevState) => [...prevState, data]);

          // Continue listening for more data
          handleEvent();
        } catch (error) {
          console.error("Error reading data:", error);
          console.log("Error reading data:", error.name);
          if (error.name === "NetworkError") {
            alert("The device was disconnected.");
          }
          // check if The device has been lost.
          disconnect();
        }
      };

      // Add the "readable" event listener
      reader.read().then(handleEvent);

      // Save the port and reader in state or variables for future reference
      setSerialPort(port);
      setReader(reader);
    } catch (error) {
      console.error("Error requesting serial port:", error);
      console.error("Error requesting serial port:", error.name);
      if (error.name === "NotFoundError") {
        alert("No device selected.");
      } else if (error.name === "InvalidStateError") {
        alert("Disconnect device and connect again.");
      }
    }
  };

  const disconnect = async () => {
    try {
      if (reader) {
        // Cancel the ongoing read operation
        await reader.cancel();
        console.log("Cancelled ongoing read operation.");
      }

      if (serialPort) {
        // Close the port
        await serialPort.close();
        console.log("Disconnected from the serial port.");

        // Update state or variables accordingly
        setSerialPort(null);
        setReader(null);
      }
    } catch (error) {
      console.error("Error during disconnect:", error);
      console.log("Error during disconnect:", error.name);
      if (error.name === "NetworkError") {
        setSerialPort(null);
        setReader(null);
      }
    }
  };

  const writeData = async (data) => {
    console.log("writeData", data);
    try {
      if (!serialPort) {
        console.log("witeData: no port");
        alert("No port selected.");
        return;
      }
      const writer = serialPort.writable.getWriter();
      const encoder = new TextEncoder();
      data = encoder.encode(data + "\r");
      await writer.write(data);
      await writer.close();
    } catch (error) {
      console.error("Error writing to serial port:", error);
    }
  };

  return (
    <div className="container mt-5">
      <button className="btn btn-primary" onClick={connect}>
        Připojit zařízení USB
      </button>
      <button className="btn btn-danger" onClick={disconnect}>
        Odpojit zařízení USB
      </button>

      <div className="mt-5">
        {
          /*serialPort &&*/ <>
            <Configurator
              config={config}
              setConfig={setConfig}
              writeData={writeData}
            />
            <Debug usbData={serialData} />
          </>
        }
      </div>
    </div>
  );
};

export default DeviceConfig;
