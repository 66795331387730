import React, { useEffect, useState } from "react";

import { Card } from "./Card";

const api_url = process.env.REACT_APP_API_URL;

export const Statistics = (props) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = () => {
    const token = localStorage.getItem("token");
    fetch(api_url + "/device/" + props.id + "/data", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          console.log(res);
        }
      })
      .then((device_data) => {
        console.log("devide.js", device_data);
        setData(device_data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  if (loading || !data) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className="row no-gutters">
        <div className="col-xl-3 col-md-6 mb-4">
          <Card
            title="Exported power today"
            value={data.data.energy_import_today}
            unit="kWh"
            icon="fa-solid fa-solar-panel"
          />
        </div>
        <div className="col-xl-3 col-md-6 mb-4">
          <Card
            title="Imported power today"
            value={data.data.energy_export_today}
            unit="kWh"
            icon="fa-solid fa-solar-panel"
          />
        </div>
        <div className="col-xl-3 col-md-6 mb-4">
          <Card
            title="Exported power total"
            value={data.data.energy_import_total}
            unit="kWh"
            icon="fa-solid fa-solar-panel"
          />
        </div>
        <div className="col-xl-3 col-md-6 mb-4">
          <Card
            title="Imported power total"
            value={data.data.energy_export_total}
            unit="kWh"
            icon="fa-solid fa-solar-panel"
          />
        </div>
        <div className="col-xl-3 col-md-6 mb-4">
          <Card
            title="Battery charge today"
            value={data.data.battery_charge_today}
            unit="kWh"
            icon="fa-solid fa-battery-half"
          />
        </div>
        <div className="col-xl-3 col-md-6 mb-4">
          <Card
            title="Battery discharge today"
            value={data.data.battery_discharge_today}
            unit="kWh"
            icon="fa-solid fa-battery-half"
          />
        </div>
        <div className="col-xl-3 col-md-6 mb-4">
          <Card
            title="Battery charge total"
            value={data.data.battery_charge_total}
            unit="kWh"
            icon="fa-solid fa-battery-half"
          />
        </div>
        <div className="col-xl-3 col-md-6 mb-4">
          <Card
            title="Battery discharge total"
            value={data.data.battery_discharge_total}
            unit="kWh"
            icon="fa-solid fa-battery-half"
          />
        </div>
        <div className="col-xl-3 col-md-6 mb-4">
          <Card
            title="PV energy today"
            value={data.data.pv_energy_today}
            unit="kWh"
            icon="fa-solid fa-battery-half"
          />
        </div>
        <div className="col-xl-3 col-md-6 mb-4">
          <Card
            title="PV energy total"
            value={data.data.pv_energy_total}
            unit="kWh"
            icon="fa-solid fa-battery-half"
          />
        </div>
        <div className="col-xl-3 col-md-6 mb-4">
          <Card
            title="Today load"
            value={data.data.energy_load_today}
            unit="W"
            icon="fa-solid fa-battery-half"
          />
        </div>
        <div className="col-xl-3 col-md-6 mb-4">
          <Card
            title="Total load"
            value={data.data.energy_load_total}
            unit="W"
            icon="fa-solid fa-battery-half"
          />
        </div>
        <div className="col-xl-3 col-md-6 mb-4">
          <Card
            title="Running time"
            value={data.data.hours_total}
            unit="h"
            icon="fa-solid fa-battery-half"
          />
        </div>
      </div>
    );
  }
};
