const api_url = process.env.REACT_APP_API_URL;

async function getFetcher(url) {
  return fetch(api_url + url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        console.log(res);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

async function putFetcher(url, data) {
  return fetch(api_url + url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (res.status === 200) {
        alert("Success");
        return res.json();
      } else {
        alert("Failed");
        console.log(res);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

async function postFetcher(url, data) {
  return fetch(api_url + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (res.status === 200) {
        alert("Success");
        return res.json();
      } else {
        alert("Failed");
        console.log(res);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

export { getFetcher, putFetcher, postFetcher };
