import React, { useState, useEffect } from "react";
import {
  ComposedChart,
  Line,
  Area,
  Label,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
const moment = require("moment");

const api_url = process.env.REACT_APP_API_URL;

export const SpotGraph = (props) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const treshold = props.device.config.export_limit;

  //let tmp_data = [];

  useEffect(() => {
    setLoading(true);

    getHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /*
  useEffect(() => {
    console.log("treshold update data", treshold);
    if (data.length > 0) {
      console.log("data.price", data);
      setData(compareData(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treshold]);
  */

  const compareData = (data) => {
    console.log("data", data);
    data.forEach((element) => {
      if (element.value > treshold) {
        element.limitation = 0;
      } else {
        element.limitation = 100;
      }
    });
    return data;
  };
  /*
  const addLoad_data = (data) => {
    console.log("data", data);
    tmp_data.forEach((element) => {
      data.forEach((element2) => {
        if (element.time === element2.time) {
          if (element2.ac_active_power < 0) {
            element.import = element2.ac_active_power * -1;
            element.export = 0;
          } else {
            element.import = 0;
            element.export = element2.ac_active_power;
          }
        }
      });
    });

    return tmp_data;
  };
  */

  const dateFormater = (tickItem) => {
    return moment(tickItem).format("DD.MM HH:mm");
  };

  const tootlTipFormater = (value, name, props) => {
    if (name === "value") {
      const tmp_val = String(value) + " €/MWh";
      return [tmp_val, "spot", props];
    } else if (name === "export" || name === "import") {
      const tmp_val = String(value) + " W";
      return [tmp_val, name, props];
    } else {
      if (value === 0) {
        return ["off", name, props];
      } else {
        return ["on", name, props];
      }
    }
  };

  const getHistory = () => {
    const token = localStorage.getItem("token");
    const start = moment()
      .startOf("day")
      .subtract(2, "hours")
      .subtract(1, "days")
      .format("YYYY-MM-DDTHH:mm:ss.SSS");
    const stop = moment()
      .endOf("day")
      .subtract(1, "hours")
      .format("YYYY-MM-DDTHH:mm:ss.SSS");
    console.log("start", start);
    console.log("stop", stop);

    //get spot data
    fetch(api_url + "/spot/CZ?start=" + start + "Z&stop=" + stop + "Z", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          console.log(res);
        }
      })
      .then((device_data) => {
        const tmp = compareData(device_data.price);
        //tmp_data = tmp;
        console.log("spot_data", device_data);
        console.log("data", tmp);
        setData(tmp);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    //get ac_active_power data
    /*
    fetch(
      api_url +
        "/device/" +
        props.id +
        "/querry?start=" +
        start +
        "Z&querry=ac_active_power",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          console.log(res);
        }
      })
      .then((device_data) => {
        console.log("querry_data", device_data.data);
        tmp_data = addLoad_data(device_data.data);
        console.log("tmp_data", tmp_data);
        setData(tmp_data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
      */
  };

  return (
    <div className="card border-left-primary shadow h-100 p-2">
      <div className="card-body">
        <div className="row no-gutters align-items-center">
          <div className="col mr-2">
            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
              SPOT
            </div>
          </div>
        </div>
      </div>
      {/*
      <div>
        <div className="row">
          <div className=" col-md-4">
            <div class="input-group mb-3">
              <span class="input-group-text" id="basic-addon1">
                Export limitation
              </span>
              <input
                type="number"
                class="form-control"
                placeholder="Export limitation"
                aria-label="Export limitation"
                aria-describedby="basic-addon2"
                value={treshold}
                onChange={(e) => setTreshold(e.target.value)}
              />
              <span class="input-group-text" id="basic-addon2">
                €/kWh
              </span>
            </div>
          </div>
        </div>
      </div>
      */}
      {loading === true ? (
        <div>
          Loading...
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"></span>
        </div>
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: -10,
              bottom: 5,
            }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="time"
              tick={{ fontSize: "10px" }}
              angle={-45}
              height={60}
              dy={20}
              interval={1}
              tickFormatter={dateFormater}
            />
            <YAxis unit={"€"} tick={{ fontSize: "12px" }} yAxisId={"left"}>
              <Label
                value="€/MWh"
                position="insideLeft"
                angle={-90}
                dx={10}
                style={{ textAnchor: "middle" }}
              />
            </YAxis>
            <YAxis
              height={60}
              unit={"%"}
              tick={{ fontSize: "12px" }}
              yAxisId={"right"}
              orientation="right">
              <Label
                value="Limitation"
                position="insideRight"
                angle={-90}
                style={{ textAnchor: "middle" }}
              />
            </YAxis>

            <YAxis unit={"W"} tick={{ fontSize: "12px" }} yAxisId={"left_load"}>
              <Label
                value="W"
                position="insideLeft"
                angle={-90}
                style={{ textAnchor: "middle" }}
              />
            </YAxis>
            <ReferenceLine
              y={treshold}
              label="Treshold"
              stroke="red"
              yAxisId={"left"}
            />
            <Tooltip
              formatter={tootlTipFormater}
              labelFormatter={dateFormater}
            />
            <Legend
              formatter={(name) =>
                name === "value"
                  ? "spot €/MWh"
                  : name === "export"
                  ? "export W"
                  : name === "import"
                  ? "import W"
                  : "limitation"
              }
            />
            <Area
              type="monotone"
              dataKey="value"
              connectNulls
              stroke="#195896"
              fill="#72b0eb"
              yAxisId={"left"}
            />
            <Area
              type="monotone"
              dataKey="export"
              connectNulls
              stroke="#19961d"
              fill="#8dee82"
              yAxisId={"left_load"}
            />

            <Area
              type="monotone"
              dataKey="import"
              connectNulls
              stroke="#d70a0a"
              fill="#bf6666"
              yAxisId={"left_load"}
            />

            <Line
              dot={false}
              type="monotone"
              dataKey="limitation"
              //connectNulls
              stroke="#3d0980"
              fill="#a87ae4"
              yAxisId={"right"}
            />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};
