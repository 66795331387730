import React, { useRef, useEffect } from "react";

const Debug = (props) => {
  const textAreaRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom of the textarea when usbData changes
    textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
  }, [props.usbData]);

  return (
    <div className="card mt-5">
      <div className="card-header">
        <h4 className="card-title">Debug</h4>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="ssid">USB data</label>
              <textarea
                className="form-control"
                ref={textAreaRef}
                id="usbData"
                rows="10"
                value={props.usbData.toString().replace(/,/g, " ")}
                readOnly></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Debug;
