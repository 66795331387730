import React, { useState } from "react";
import Switch from "./switch";

export const Settings = (props) => {
  const [exportLimitation, setExportLimitation] = useState(
    props.device.config.enable_export_limit
  );
  const [exportLimitationValue, setExportLimitationValue] = useState(
    props.device.config.export_limit
  );
  //const [maxTemp, setMaxTemp] = useState(80);
  //const [minTemp, setMinTemp] = useState(40);
  //const [enableHDO, setEnableHDO] = useState(false);
  //const [enableBoiler, setEnableBoiler] = useState(false);

  const handleExportLimitation = () => {
    console.log("Export limitation: " + !exportLimitation);
    setExportLimitation(!exportLimitation);
  };

  const handleExportLimitationValue = (e) => {
    console.log("Export limitation value: " + e.target.value);
    setExportLimitationValue(e.target.value);
  };
  /*
  const handleMaxTemp = (e) => {
    console.log("Max temperature: " + e.target.value);
    setMaxTemp(e.target.value);
  };

  const handleMinTemp = (e) => {
    console.log("Min temperature: " + e.target.value);
    setMinTemp(e.target.value);
  };

  const handleEnableHDO = () => {
    console.log("Enable HDO switch: " + !enableHDO);
    setEnableHDO(!enableHDO);
  };

  const handleEnableBoiler = () => {
    console.log("Enable boiler switch: " + !enableBoiler);
    setEnableBoiler(!enableBoiler);
  };
  */

  return (
    <>
      <div className="container-fluid">
        <h1 className="h3 mb-2 text-gray-800">Settings</h1>
        <div class="accordion" id="accordionPanelsStayOpenExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseOne"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseOne">
                Spot settings
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="panelsStayOpen-headingOne">
              <div class="accordion-body">
                <div className="row">
                  <div className=" col-md-4">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">
                        Enable export limitation
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6"></div>
                  <div className="col-md-2">
                    <Switch
                      isToggled={exportLimitation}
                      onToggle={handleExportLimitation}
                    />
                  </div>
                </div>
                <div className="row">
                  <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">
                      Export limitation
                    </span>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Export limitation"
                      aria-label="Export limitation"
                      aria-describedby="basic-addon2"
                      value={exportLimitationValue}
                      onChange={handleExportLimitationValue}
                    />
                    <span class="input-group-text" id="basic-addon2">
                      €/MWh
                    </span>
                  </div>
                </div>
                {/*save button*/}
                <div className="row">
                  <div className="col-md-4">
                    <button type="button" class="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseTwo">
                Boiler settings
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseTwo"
              class="accordion-collapse collapse">
              <div class="accordion-body">
                <div className="row">
                  <div className=" col-md-4">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">
                        Enable Boiler control
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6"></div>
                  <div className="col-md-2">
                    <Switch
                      isToggled={enableBoiler}
                      onToggle={handleEnableBoiler}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className=" col-md-4">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">
                        Enable HDO switch
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6"></div>
                  <div className="col-md-2">
                    <Switch isToggled={enableHDO} onToggle={handleEnableHDO} />
                  </div>
                </div>
                <div className="row">
                  <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">
                      Minimal temperature
                    </span>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Minimal temperature"
                      aria-label="Minimal temperature"
                      aria-describedby="basic-addon2"
                      value={minTemp}
                      onChange={handleMinTemp}
                    />
                    <span class="input-group-text" id="basic-addon2">
                      °C
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">
                      Maximal temperature
                    </span>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Maximal temperature"
                      aria-label="Maximal temperature"
                      aria-describedby="basic-addon2"
                      value={maxTemp}
                      onChange={handleMaxTemp}
                    />
                    <span class="input-group-text" id="basic-addon2">
                      °C
                    </span>
                  </div>
                </div>
                {/*save button}
                <div className="row">
                  <div className="col-md-4">
                    <button type="button" class="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          */}
        </div>
      </div>
    </>
  );
};
