import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  ComposedChart,
  Area,
  Label,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
  ResponsiveContainer,
} from "recharts";
const moment = require("moment");

const api_url = process.env.REACT_APP_API_URL;

export const LineGraph = (props) => {
  const { t } = useTranslation("global");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [start, setStart] = useState(
    moment(moment().startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"))
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss.SSS")
  );
  const [stop, setStop] = useState(
    moment(moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS"))
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss.SSS")
  );

  useEffect(() => {
    setLoading(true);

    getHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start]);

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log("getDevice");
      getHistory();
    }, 1 * 60 * 1000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const handleSelect = (e) => {
    console.log(e.target.value);
    console.log("select");
    let tmp_start;
    let tmp_stop;
    if (e.target.value === "today") {
      tmp_start = moment().startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS");
      tmp_start = moment(tmp_start).utc().format("YYYY-MM-DDTHH:mm:ss.SSS");
      tmp_stop = moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS");
      tmp_stop = moment(tmp_stop).utc().format("YYYY-MM-DDTHH:mm:ss.SSS");
    } else {
      tmp_start = moment
        .utc()
        .subtract(Number(e.target.value), "hours")
        .format("YYYY-MM-DDTHH:mm:ss.SSS");
      tmp_stop = moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSS");
    }
    setStart(tmp_start);
    setStop(tmp_stop);
    console.log("start", e.target.value, tmp_start);
  };

  const getHistory = () => {
    const token = localStorage.getItem("token");
    fetch(
      api_url +
        "/device/" +
        props.id +
        "/history?start=" +
        start +
        "Z&stop=" +
        stop +
        "Z",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          console.log(res);
        }
      })
      .then((device_data) => {
        setData(device_data.data);
        setLoading(false);
        console.log("graph_data", device_data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const dateFormater = (tickItem) => {
    return moment(tickItem).format("DD.MM HH:mm");
  };

  const brushFormater = (tickItem, value) => {
    //console.log("tickItem", tickItem);
    //console.log("value", value);
    return moment(data[tickItem].time).format("DD.MM HH:mm");
  };

  console.log("graph", props);
  return (
    <div className="card border-left-primary shadow p-2">
      <div className="card-body">
        <div className="row no-gutters align-items-center">
          <div className="col mr-2">
            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
              {t("device.production")}
            </div>
          </div>
          <div
            className="col col-auto input-group"
            style={{ maxWidth: "200px" }}>
            <span className="input-group-text">{t("general.last")}</span>
            <select
              className="form-select"
              id="inputGroupSelect01"
              onChange={handleSelect}>
              <option value="today" selected>
                {t("general.today")}
              </option>
              <option value="1">{t("general.1h")}</option>
              <option value="2">{t("general.2h")}</option>
              <option value="3">{t("general.3h")}</option>
              <option value="6">{t("general.6h")}</option>
              <option value="12">{t("general.12h")}</option>
              <option value="24">{t("general.24h")}</option>
              <option value="48">{t("general.2d")}</option>
            </select>
          </div>
        </div>
      </div>
      {loading && !data ? (
        <div>
          {t("general.loading")}
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"></span>
        </div>
      ) : (
        <ResponsiveContainer width="100%" height="100%" aspect={2.5}>
          <ComposedChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}>
            <Brush tickFormatter={brushFormater} />
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="time"
              tick={{ fontSize: "10px" }}
              angle={-45}
              height={60}
              dy={20}
              ticksMargin={10}
              tickFormatter={dateFormater}
            />
            <YAxis unit={"W"} tick={{ fontSize: "12px" }} yAxisId={"left"}>
              <Label
                value="W"
                position="insideLeft"
                angle={-90}
                style={{ textAnchor: "middle" }}
              />
            </YAxis>
            <YAxis
              unit={"%"}
              tick={{ fontSize: "12px" }}
              ticks={[0, 25, 50, 75, 100]}
              padding={{ top: 20, bottom: 20 }}
              yAxisId={"right"}
              orientation="right">
              <Label
                value="%"
                position="insideRight"
                angle={-90}
                style={{ textAnchor: "middle" }}
              />
            </YAxis>
            <YAxis
              unit={"°C"}
              tick={{ fontSize: "12px" }}
              ticks={[0, 25, 50, 75, 100]}
              padding={{ top: 20, bottom: 20 }}
              yAxisId={"right_temp"}
              orientation="right">
              <Label
                value="°C"
                position="insideRight"
                angle={-90}
                style={{ textAnchor: "middle" }}
              />
            </YAxis>
            <Tooltip labelFormatter={dateFormater} />
            <Legend />
            <Area
              type="monotone"
              dataKey="battery_soc"
              connectNulls
              stroke="#4cbb20"
              fill="#90ce78"
              yAxisId={"right"}
            />
            <Area
              type="monotone"
              dataKey="radiator_temperature"
              connectNulls
              stroke="#bb5e20"
              fill="#bb5e2067"
              yAxisId={"right_temp"}
            />
            <Area
              type="monotone"
              dataKey="pv_power"
              connectNulls
              stroke="#bbb120"
              fill="#d8d177"
              yAxisId={"left"}
            />
            <Area
              type="monotone"
              dataKey="load_power"
              connectNulls
              stroke="#195896"
              fill="#72b0eb"
              yAxisId={"left"}
            />
            <Area
              type="monotone"
              dataKey="load_L1_power"
              connectNulls
              stroke="#195896"
              fill="#72b0eb"
              yAxisId={"left"}
            />
            <Area
              type="monotone"
              dataKey="load_L2_power"
              connectNulls
              stroke="#195896"
              fill="#72b0eb"
              yAxisId={"left"}
            />
            <Area
              type="monotone"
              dataKey="load_L3_power"
              connectNulls
              stroke="#195896"
              fill="#72b0eb"
              yAxisId={"left"}
            />
            <Area
              type="monotone"
              dataKey="grid_power"
              connectNulls
              stroke="#199651"
              fill="#72e6a6"
              yAxisId={"left"}
            />
            <Area
              type="monotone"
              dataKey="grid_L1_power"
              connectNulls
              stroke="#199651"
              fill="#72e6a6"
              yAxisId={"left"}
            />
            <Area
              type="monotone"
              dataKey="grid_L2_power"
              connectNulls
              stroke="#199651"
              fill="#72e6a6"
              yAxisId={"left"}
            />
            <Area
              type="monotone"
              dataKey="grid_L3_power"
              connectNulls
              stroke="#199651"
              fill="#72e6a6"
              yAxisId={"left"}
            />
            <Area
              type="monotone"
              dataKey="eps_power"
              connectNulls
              stroke="#bba420"
              fill="#e6d575"
              yAxisId={"left"}
            />
            <Area
              type="monotone"
              dataKey="eps_L1_power"
              connectNulls
              stroke="#bba420"
              fill="#e6d575"
              yAxisId={"left"}
            />
            <Area
              type="monotone"
              dataKey="eps_L2_power"
              connectNulls
              stroke="#bba420"
              fill="#e6d575"
              yAxisId={"left"}
            />
            <Area
              type="monotone"
              dataKey="eps_L3_power"
              connectNulls
              stroke="#bba420"
              fill="#e6d575"
              yAxisId={"left"}
            />
            <Area
              type="monotone"
              dataKey="battery_power"
              connectNulls
              stroke="#bb5920"
              fill="#d09471"
              yAxisId={"left"}
            />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};
