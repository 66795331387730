import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./sites/Home";
import NotFound from "./sites/NotFound";
import AddUser from "./sites/AddUser";
import Devices from "./sites/Devices";
import LoginPage from "./sites/Login";
import RegisterPage from "./sites/Register";
import Account from "./sites/Account";
import Documentation from "./sites/Docs";
import Device from "./sites/Device";

import { AuthProvider } from "./context/AuthProvider";
import { NavBar } from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import UnAuthorized from "./sites/UnAuthorized";

import "./App.css";
import DeviceConfig from "./sites/DeviceConfig";

function App() {
  return (
    <AuthProvider>
      <Router>
        <NavBar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/add_user" element={<AddUser />} />
          <Route path="/devices" element={<Devices />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/account" element={<Account />} />
          <Route path="/docs" element={<Documentation />} />
          <Route path="/device/:id" element={<Device />} />
          <Route path="device/config" element={<DeviceConfig />} />
          <Route path="/401" element={<UnAuthorized />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
    </AuthProvider>
  );
}

export default App;
