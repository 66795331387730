import { createContext, useState } from "react";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [user, setUser] = useState({});
  const [token, setToken] = useState("");
  const [usbSerialPort, setUsbSerialPort] = useState(null);
  const [connected, setConnected] = useState(false);
  const [device, setDevice] = useState({});

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        setIsAuth,
        user,
        setUser,
        token,
        setToken,
        usbSerialPort,
        setUsbSerialPort,
        connected,
        setConnected,
        device,
        setDevice,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
