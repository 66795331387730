import React, { useState } from "react";

const Configurator = (props) => {
  const [firmware, setFirmware] = useState(null);
  const [firmwareList, setFirmwareList] = useState([]);
  const [inverter, setInverter] = useState("");

  console.log("props debug", props, props.config);

  const saveWifiConfig = () => {
    console.log("saveWifiConfig");
    let data = {
      wifi_setup: {
        ssid: props.config.ssid,
        password: props.config.password,
      },
    };
    console.log("data", JSON.stringify(data));
    props.writeData(JSON.stringify(data));
  };

  const saveInverterConfig = () => {
    console.log("safeInverterConfig");
    let data = {
      inverter_setup: inverter,
    };
    console.log("data", JSON.stringify(data));
    props.writeData(JSON.stringify(data));
  };

  const loadConfig = () => {
    console.log("loadConfig");
    let data = {
      read_config: true,
    };
    props.writeData(JSON.stringify(data));
  };

  const handleChange = (e) => {
    console.log(e.target.id, e.target.value);
    if (e.target.id === "ssid") {
      props.setConfig({ ...props.config, ssid: e.target.value });
    } else if (e.target.id === "password") {
      props.setConfig({ ...props.config, password: e.target.value });
    }
  };

  const getFirmwareList = () => {
    fetch("http://solarbeam.vojtechpetrasek.com:9000/fws/list", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("firmware list", data);
        setFirmwareList(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const updateFirmware = () => {
    if (firmware === null) {
      alert("Choose firmware");
      return;
    }
    console.log("updateFirmware");
    let updateData = {
      update: {
        url: "http://solarbeam.vojtechpetrasek.com:9000/fw/" + firmware,
      },
    };
    try {
      fetch(updateData.update.url, {
        method: "GET",
      })
        .then((res) => {
          console.log("firmware update", res);
          props.writeData(JSON.stringify(updateData));
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("Error: " + error);
        });
    } catch (e) {
      console.log("error", e);
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <ul class="nav">
          <li class="nav-item">
            <h4 className="card-title">Configurator</h4>
          </li>
          <li class="nav-item ms-auto">
            <button className="btn btn-primary" onClick={loadConfig}>
              Load Configuration
            </button>
          </li>
        </ul>
      </div>
      <div className="card-body">
        <div className="row">
          <p>Wifi config</p>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="ssid">SSID</label>
              <input
                type="text"
                className="form-control"
                id="ssid"
                placeholder="SSID"
                value={props.config.ssid}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="text"
                className="form-control"
                id="password"
                placeholder="Password"
                value={props.config.password}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <button className="btn btn-primary" onClick={saveWifiConfig}>
          Save
        </button>
        <hr class="hr" />
        <p>Firmware update</p>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="firmware">Firmware</label>
              <select
                className="form-select"
                id="firmware"
                onChange={(e) => setFirmware(e.target.value)}>
                <option selected>Choose...</option>
                {firmwareList &&
                  firmwareList.map((firmware) => (
                    <option value={firmware.fw}>{firmware.version}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <button
              className="btn btn-primary"
              onClick={() => getFirmwareList()}>
              get firmware list
            </button>
            <button
              className="btn btn-primary"
              style={{ position: "relative", left: "45%", top: "40%" }}
              onClick={updateFirmware}>
              Update
            </button>
          </div>
        </div>
        <hr class="hr" />
        <p>Inverter type</p>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="inverter">Inverter</label>
              <select
                className="form-select"
                id="inverter"
                onChange={(e) => setInverter(e.target.value)}>
                <option selected>Choose...</option>
                <option value="GoodWe">GoodWe</option>
                <option value="Wattsonic">Wattsonic</option>
                <option value="Growatt">Growatt</option>
                <option value="Solax">Solax</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="inverter">Inverter RS485 address</label>
              <input
                type="number"
                className="form-control"
                id="inverter"
                placeholder="Inverter RS485 address"
                defaultValue={props.config.inverter || 1}
              />
            </div>
          </div>
          <div className="col-md-6">
            <button
              className="btn btn-primary"
              style={{ position: "relative", right: "-80%", top: "70%" }}
              onClick={saveInverterConfig}>
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Configurator;
