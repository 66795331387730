import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Dashboard } from "../components/EMS/Dashboard";
import { Settings } from "../components/Settings/Settings";
import { Spot } from "../components/Spot/Spot";
import { Graphs } from "../components/Graphs/Graphs";
import { Statistics } from "../components/Statistics/Statistics";
import { SLCDashboard } from "../components/SLC/Dashboard";
import { SLCSettings } from "../components/SLC/Settings";

const api_url = process.env.REACT_APP_API_URL;

const Device = () => {
  const { t } = useTranslation("global");
  let { id } = useParams();
  //const navigate = useNavigate();
  const [device, setDevice] = useState({});
  const [loading, setLoading] = useState(true);

  const [menu, setMenu] = useState("Dashboard");

  useEffect(() => {
    setLoading(true);
    getDevice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  /*
  useEffect(() => {
    const timer = setTimeout(() => {
      console.log("getDevice");
      getDevice();
    }, 10000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });
  */

  const getDevice = () => {
    console.log(id);
    const token = localStorage.getItem("token");
    fetch(api_url + "/device/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        /*
        if (res.status === 401) {
          navigate("/401");
        } else if (res.status === 404) {
          navigate("/404");
        } else
        */
        if (res.status === 200) {
          return res.json();
        }
      })
      .then((data) => {
        console.log("device.js", data);
        setDevice(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  if (loading) {
    return (
      <div className="container pt-5">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">{t("general.loading")}</span>
        </div>
      </div>
    );
  } else {
    if (device.type === "EMS") {
      return (
        <>
          <div
            className="container-fluid pt-5"
            style={{ paddingLeft: "10%", paddingRight: "10%" }}>
            <div className="card">
              <div class="card-header">
                <h4>{device.name}</h4>
                {/*
                <ul class="nav nav-tabs card-header-tabs">
                  <li class="nav-item">
                    <h4>{device.name}</h4>
                  </li>
                  <li class="nav-item ms-auto">
                    <i class="fa fa-refresh fa-spin" aria-hidden="true"></i>
                  </li>
                </ul>
      */}
                <ul class="nav nav-tabs card-header-tabs">
                  <li class="nav-item">
                    <button
                      class={
                        "nav-link" + (menu === "Dashboard" ? " active" : "")
                      }
                      onClick={() => setMenu("Dashboard")}>
                      {t("device.dashboard")}
                    </button>
                  </li>
                  <li class="nav-item">
                    <button
                      class={
                        "nav-link" + (menu === "Statistics" ? " active" : "")
                      }
                      onClick={() => setMenu("Statistics")}>
                      {t("device.statistics")}
                    </button>
                  </li>
                  {/*<li class="nav-item">
                  <button
                    class={"nav-link" + (menu === "Graphs" ? " active" : "")}
                    onClick={() => setMenu("Graphs")}>
                    Graphs
                  </button>
                  </li>*/}
                  <li class="nav-item">
                    <button
                      class={
                        "nav-link" + (menu === "Spot prices" ? " active" : "")
                      }
                      onClick={() => setMenu("Spot prices")}>
                      {t("device.spot_prices")}
                    </button>
                  </li>
                  <li class="nav-item ms-auto">
                    <button
                      class={
                        "nav-link" + (menu === "Settings" ? " active" : "")
                      }
                      onClick={() => setMenu("Settings")}>
                      {t("device.settings")}
                    </button>
                  </li>
                </ul>
              </div>
              <div className="card-body">
                {menu === "Dashboard" ? <Dashboard id={id} /> : null}
                {menu === "Statistics" ? <Statistics id={id} /> : null}
                {menu === "Graphs" ? <Graphs id={id} /> : null}
                {menu === "Spot prices" ? <Spot device={device} /> : null}
                {menu === "Settings" ? <Settings device={device} /> : null}
              </div>
            </div>
          </div>
        </>
      );
    } else if (device.type === "SLC") {
      return (
        <>
          <div
            className="container-fluid pt-5"
            style={{ paddingLeft: "10%", paddingRight: "10%" }}>
            <div className="card">
              <div class="card-header">
                <h4>
                  {device.name} {device.slc}
                </h4>
                <ul class="nav nav-tabs card-header-tabs">
                  <li class="nav-item">
                    <button
                      class={
                        "nav-link" + (menu === "Dashboard" ? " active" : "")
                      }
                      onClick={() => setMenu("Dashboard")}>
                      {t("device.dashboard")}
                    </button>
                  </li>
                  {/*<li class="nav-item">
                    <button
                      class={
                        "nav-link" + (menu === "Statistics" ? " active" : "")
                      }
                      onClick={() => setMenu("Statistics")}>
                      Statistics
                    </button>
                  </li>
                  <li class="nav-item">
                  <button
                    class={"nav-link" + (menu === "Graphs" ? " active" : "")}
                    onClick={() => setMenu("Graphs")}>
                    Graphs
                  </button>
                  </li>
                  <li class="nav-item">
                    <button
                      class={
                        "nav-link" + (menu === "Spot prices" ? " active" : "")
                      }
                      onClick={() => setMenu("Spot prices")}>
                      Spot prices
                    </button>
                  </li>*/}
                  <li class="nav-item ms-auto">
                    <button
                      class={
                        "nav-link" + (menu === "Settings" ? " active" : "")
                      }
                      onClick={() => setMenu("Settings")}>
                      {t("device.settings")}
                    </button>
                  </li>
                </ul>
              </div>
              <div className="card-body">
                {menu === "Dashboard" ? <SLCDashboard id={id} /> : null}
                {menu === "Settings" ? <SLCSettings device={device} /> : null}
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="container-fluid pt-5"
            style={{ paddingLeft: "10%", paddingRight: "10%" }}>
            <div className="card">
              <div class="card-header">
                <h4>Unsupported device</h4>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
};

export default Device;
