import React from "react";
import { Link } from "react-router-dom";
import gandalf from "../images/gandalf.png";

const UnAuthorized = (props) => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-center pt-3">
        <div className="card mb-4">
          <div className="card-header py-3">
            <h1 className="m-0 font-weight-bold text-primary">
              401 - Unauthorized
            </h1>
          </div>
          <div className="card-body">
            <img src={gandalf} alt="Gandalf" />

            <h1>You shall not pass!</h1>

            <p>You are not authorized to view this page.</p>
            <p>
              If you think this is a mistake, please contact your system
              administrator.
            </p>
            <p>
              If you are a system administrator, please make sure you are logged
              in.
            </p>
            <Link to="/">&larr; Back to Home</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnAuthorized;
