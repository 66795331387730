import React from "react";

import "./Card.css";

export const Card = (props) => {
  return (
    <>
      <div className="card border-left-primary shadow h-100 p-2">
        <div className="card-body">
          <div className="row no-gutters align-items-center">
            <div className="col mr-2">
              <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                {props.title}
              </div>
              <div className="h5 mb-0 font-weight-bold text-gray-800">
                {props.value} {props.unit}
              </div>
            </div>
            <div className="col-auto">
              <i className={props.icon + " fa-2xl"}></i>
              <i className={props.icon2 + " fa-2x"}></i>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
