import React, { useState } from "react";
import "./settings.css";
import { putFetcher } from "../fetcher";

import { useTranslation } from "react-i18next";
import Switch from "../Settings/switch";

export const SLCSettings = (props) => {
  const { t } = useTranslation("global");
  console.log("SLCSettings", props);

  const [minTemp, setMinTemp] = useState(props.device.config.min_temp);
  const [maxTemp, setMaxTemp] = useState(props.device.config.max_temp);
  const [maxHeat, setMaxHeat] = useState(props.device.config.max_heat);
  const [time_end, setTimeEnd] = useState(props.device.config.time_end);
  const [time_start, setTimeStart] = useState(props.device.config.time_start);
  const [time_control, settime_control] = useState(
    props.device.config.time_control
  );
  const [battery_control, setbattery_control] = useState(
    props.device.config.battery_control
  );
  const [min_battery, setmin_battery] = useState(
    props.device.config.min_battery
  );
  const [max_battery, setmax_battery] = useState(
    props.device.config.max_battery
  );

  const [export_power, setExport_power] = useState(props.device.config.power);

  const [export_control, setexport_control] = useState(
    props.device.config.export_control
  );

  const handleExport_control = (e) => {
    setexport_control(!export_control);
  };

  const handleExport_power = (e) => {
    e.preventDefault();
    setExport_power(e.target.value);
  };

  const handleBattery_control = (e) => {
    setbattery_control(!battery_control);
  };

  const handleMin_battery = (e) => {
    e.preventDefault();
    setmin_battery(e.target.value);
  };

  const handleMax_battery = (e) => {
    e.preventDefault();
    setmax_battery(e.target.value);
  };

  const handleStart = (e) => {
    e.preventDefault();
    setTimeStart(e.target.value);
  };

  const handleEnd = (e) => {
    e.preventDefault();
    setTimeEnd(e.target.value);
  };

  const handletime_control = (e) => {
    console.log("time_control", e.target.value);
    settime_control(!time_control);
  };

  const handleMinTemp = (e) => {
    e.preventDefault();
    setMinTemp(e.target.value);
  };

  const handleMaxTemp = (e) => {
    e.preventDefault();
    setMaxTemp(e.target.value);
  };

  const handleMaxHeat = (e) => {
    e.preventDefault();
    setMaxHeat(e.target.value);
  };

  const handleSave = () => {
    putFetcher("/device/config/" + props.device._id, {
      min_temp: minTemp === undefined ? 50 : minTemp,
      max_temp: maxTemp === undefined ? 55 : maxTemp,
      max_heat: maxHeat === undefined ? 75 : maxHeat,
      time_end: time_end === undefined ? "17:00" : time_end,
      time_start: time_start === undefined ? "15:00" : time_start,
      time_control: time_control === undefined ? false : time_control,
      battery_control: battery_control === undefined ? false : battery_control,
      min_battery: min_battery === undefined ? 95 : min_battery,
      max_battery: max_battery === undefined ? 98 : max_battery,
      power: export_power === undefined ? 2000 : export_power,
      export_control: export_control === undefined ? false : export_control,
    }).then((data) => {
      console.log("putFetcher", data);
    });
  };

  return (
    <>
      <div className="container-fluid">
        <h1 className="h3 mb-2 text-gray-800">{t("slc_settings.title")}</h1>
        <div class="accordion" id="accordionPanelsStayOpenExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseOne"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseOne">
                Thermostat settings
              </button>
            </h2>

            <div
              id="panelsStayOpen-collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="panelsStayOpen-headingOne">
              <div class="accordion-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        Min thermostat temperature
                      </span>
                      <input
                        type="number"
                        class="form-control pull-right"
                        placeholder="Min temperature"
                        aria-label="Min temperature"
                        aria-describedby="basic-addon1"
                        value={minTemp}
                        onChange={handleMinTemp}
                      />
                      <span class="input-group-text">°C</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        Max thermostat temperature
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Max temperature"
                        aria-label="Max temperature"
                        aria-describedby="basic-addon1"
                        value={maxTemp}
                        onChange={handleMaxTemp}
                      />
                      <span class="input-group-text">°C</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        Max temperature when exporting
                      </span>

                      <input
                        type="number"
                        class="form-control"
                        placeholder="Max heat"
                        aria-label="Max heat"
                        aria-describedby="basic-addon1"
                        value={maxHeat}
                        onChange={handleMaxHeat}
                      />
                      <span class="input-group-text">°C</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseTwo">
                Time Window
              </button>
            </h2>

            <div
              id="panelsStayOpen-collapseTwo"
              class="accordion-collapse collapse">
              <div class="accordion-body">
                <div className="row">
                  <div className=" col-md-4">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">
                        Enabled
                      </span>
                    </div>
                  </div>
                  {/*<div className="col-md-6"></div>*/}
                  <div className="col-md-2">
                    <Switch
                      isToggled={time_control}
                      onToggle={handletime_control}
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        Start time
                      </span>
                      <input
                        type="time"
                        class="form-control"
                        placeholder="Start time"
                        aria-label="Start time"
                        aria-describedby="basic-addon1"
                        value={time_start}
                        onChange={handleStart}
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        End time
                      </span>
                      <input
                        type="time"
                        class="form-control"
                        placeholder="End time"
                        aria-label="End time"
                        aria-describedby="basic-addon1"
                        value={time_end}
                        onChange={handleEnd}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingThree">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseThree"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseThree">
                Solar control
              </button>
            </h2>

            <div
              id="panelsStayOpen-collapseThree"
              class="accordion-collapse collapse">
              <div class="accordion-body">
                <div className="row">
                  <div className=" col-md-4">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">
                        Battery control
                      </span>
                    </div>
                  </div>
                  {/*<div className="col-md-6"></div>*/}
                  <div className="col-md-2">
                    <Switch
                      isToggled={battery_control}
                      onToggle={handleBattery_control}
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        Min battery level
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="min battery"
                        aria-label="min battery"
                        aria-describedby="basic-addon1"
                        value={min_battery}
                        onChange={handleMin_battery}
                      />
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        Max battery level
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="max battery"
                        aria-label="max battery"
                        aria-describedby="basic-addon1"
                        value={max_battery}
                        onChange={handleMax_battery}
                      />
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className=" col-md-4">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">
                        export power
                      </span>
                    </div>
                  </div>
                  {/*<div className="col-md-6"></div>*/}
                  <div className="col-md-2">
                    <Switch
                      isToggled={export_control}
                      onToggle={handleExport_control}
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        Export power
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Export power"
                        aria-label="Export power"
                        aria-describedby="basic-addon1"
                        value={export_power}
                        onChange={handleExport_power}
                      />
                      <span class="input-group-text">W</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-1 ms-auto">
              <button
                type="button"
                class="btn btn-success"
                onClick={handleSave}>
                {t("slc_settings.save")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
