/*
Add Smart Logic Controller device
*/

import React from "react";

const AddSLC = (props) => {
  return (
    <form>
      <h5>Smart Logic Controller</h5>
      <div class="mb-3">
        <label for="name" class="form-label">
          Name
        </label>
        <input
          type="text"
          class="form-control"
          id="name"
          aria-describedby="nameHelp"
          value={props.name}
          onChange={(e) => props.setName(e.target.value)}
        />
        <div id="nameHelp" class="form-text">
          Name of the device
        </div>
      </div>
      <div class="mb-3">
        <label for="owner" class="form-label">
          Device ID
        </label>
        <input
          type="text"
          class="form-control"
          id="device_id"
          aria-describedby="deviceIdHelp"
          value={props.id}
          onChange={(e) => props.setId(e.target.value)}
        />
        <div id="deviceIdHelp" class="form-text">
          Serial number of the device
        </div>
      </div>
    </form>
  );
};

export default AddSLC;
