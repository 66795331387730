import React from "react";
import { Link } from "react-router-dom";

import yoda from "../images/yoda.png";

const NotFound = () => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-center pt-3">
        <div className="card mb-4">
          <div className="card-header py-3">
            <h1 className="m-0 font-weight-bold text-primary">
              404 - NotFound
            </h1>
          </div>
          <div className="card-body">
            <img src={yoda} alt="Yoda" />
            <h1>
              The page you seek, not found it is. Lost, you are. A 404 error, it
              is.
            </h1>
            <p>You are looking for something that does not exist.</p>
            <Link to="/">&larr; Back to Home</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
