import React, { useEffect, useState } from "react";

import { Card } from "./Card";
import { LineGraph } from "./Graph";

import { useTranslation } from "react-i18next";

const api_url = process.env.REACT_APP_API_URL;

export const Dashboard = (props) => {
  const { t } = useTranslation("global");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log("getData");
      getData();
    }, 1 * 60 * 1000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const getData = () => {
    const token = localStorage.getItem("token");
    fetch(api_url + "/device/" + props.id + "/data", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          console.log(res);
        }
      })
      .then((device_data) => {
        console.log("device.js", device_data);
        setData(device_data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  if (loading || !data) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <div className="row no-gutters">
          <div className="col-xl-3 col-md-6 mb-4">
            <Card
              title={t("device.pv_power")}
              value={data.data.pv_power}
              unit="W"
              icon="fa-solid fa-solar-panel"
            />
          </div>
          <div className="col-xl-3 col-md-6 mb-4">
            <Card
              title={t("device.battery")}
              value={data.data.battery_soc}
              unit="%"
              icon="fa-solid fa-battery-half "
            />
          </div>
          <div className="col-xl-3 col-md-6 mb-4">
            <Card
              title={t("device.consumption")}
              value={data.data.load_power}
              unit="W"
              icon="fa-solid fa-house"
            />
          </div>
          <div className="col-xl-3 col-md-6 mb-4">
            <Card
              title={t("device.grid")}
              value={data.data.grid_power}
              unit="W"
              icon="fa-solid fa-exchange"
            />
          </div>
        </div>
        <div className="row no-gutters">
          <div className="col-xl-9 col-md-4 mb-4">
            <LineGraph id={props.id} />
          </div>
          <div className="col-xl-3 col-md-4 mb-4">
            <div className="row-xl-3 mb-4">
              <div className="row-xl-3 mb-4">
                <Card
                  title="Inverter power"
                  value={data.data.total_inv_power}
                  unit="W"
                  icon="fa-solid fa-solar-panel"
                />
              </div>
              <div className="row-xl-3 mb-4"></div>
              <Card
                title="Today saved"
                value="2.54"
                unit="€"
                icon="fa-solid fa-euro-sign"
              />
            </div>
            <div className="row-xl-3 mb-4">
              <Card
                title="Today PV generated"
                value={data.data.pv_energy_today}
                unit="kWh"
                icon="fa-solid fa-solar-panel"
              />
            </div>
          </div>
        </div>
      </>
    );
  }
};
